.order-notes-accordion div {
  color: rgba(0, 0, 0, 0.6) !important;
}

.order-notes-accordion .title {
  padding: 0 !important;
}

.order-notes-accordion > .content {
  padding-top: 0 !important;
  padding-left: 1.5em !important;
}

.order-notes-accordion > .content .ui.list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  text-align: left;
}

.order-notes-accordion > .content .ui.list .item {
  margin-right: 0;
}

.order-notes-accordion > .content i.icon {
  vertical-align: text-top !important;
}

.order-notes-accordion > .content i.circle.icon {
  padding-top: 5px !important;
}

.order-notes-accordion > .content i.close.icon {
  cursor: pointer;
  padding-top: 2px !important;
  padding-right: 0 !important;
}

.order-notes-accordion > .content i.close.icon:hover {
  color: red;
}

.order-notes-accordion > .content i.mini.icon,
.order-notes-accordion > .content i.small.icon {
  padding-left: 2px !important;
  padding-right: 0 !important;
  min-width: 12px !important;
  width: 9px !important;
}

.registrant-edit-card-order-table-notes .order-notes-accordion > .content i.circle.icon {
  padding-top: 7px !important;
}
.registrant-edit-card-order-table-notes .order-notes-accordion > .content i.close.icon {
  padding-top: 4px !important;
}
