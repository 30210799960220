.ui.label.ws-indicator {
  position: fixed;
  top: 0.7rem;
  right: 1rem;
  z-index: 103;
}

/* Workaround popup jumping issue with React 18 */
/* See https://github.com/Semantic-Org/Semantic-UI-React/issues/4350 */
.ui.popup {
  /* animation-delay: 0.1s;
  animation-duration: 0.1s;
  animation-name: popupHover; */
}

@keyframes popupHover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
