body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  position: relative;
  background-color: #e6f9f3;
  line-height: normal;
  font-size: 1.25rem;
  font-weight: 400;
  /* letter-spacing: 0.1px; */
}

body,
.ui.header,
.ui.menu,
.ui.items > .item > .content > .header,
.ui.button,
.ui.message .header,
.ui.input input {
  /* font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif; */
  font-family: 'Avenir', 'Helvetica Neue', Arial, sans-serif;
}

div#root {
  height: 100%;
}

.pushable:not(body) {
  transform: none;
}

/* https://codepen.io/mandelid/pen/vwKoe */
.spinner {
  display: inline-block;
  transition: opacity linear 0.1s;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(80, 80, 80, 0.5);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  opacity: 0;
}
.spinner--active {
  opacity: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
