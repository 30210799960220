.class-started-button,
.item-button {
  filter: brightness(98%) hue-rotate(5deg);
}

.ui.button.item-button {
  font-weight: 400;
  line-height: 1.5em;
  font-size: 110%;
}

.ui.card .content .item-description img {
  display: none;
}

.ui.card .content .item-description {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 85%;
}

.ui.grid > .row.item-description {
  padding-top: 0;
}

.ui.items > .item .extra.item-extra {
  display: flex;
  align-items: center;
}

.ui.items > .item .extra.item-extra .ui.label {
  padding: 0.6em 0.833em 0.4em;
}

/* Calendar month/date icon used for some items' description */
.vcex-recent-news-date span.day {
  font-size: 34px;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  width: 100%;
}
.vcex-recent-news-date span.month {
  font-size: 14px;
  line-height: 1.4em;
  /*background-color: #C72933;*/
  background-color: #ca3536;
  border-radius: 0 0 4px 4px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
}

.my-news-date {
  display: flex;
  flex-direction: column;
  position: inherit;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: 68px;
  padding: 0;
}
@media only screen and (max-width: 959px) {
  .my-news-date {
    display: none;
  }
}

.vc_row {
  display: flex;
  gap: 1.5em;
  align-items: center;
}

.vc_row :first-child {
  align-self: start;
}

.grid-statistics .ui.card > .content,
.grid-statistics .ui.cards > .card > .content {
  padding: 0;
}

.grid-statistics .ui.celled.grid > .column:not(.row),
.grid-statistics .ui.celled.grid > .row > .column {
  padding: 0.5em 1em;
}

[class*='horizontally padded'].ui.grid.grid-statistics {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.grid-statistics .ui.container.item-card-grid {
  flex-direction: column;
  height: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.grid-statistics .item-statistics {
  flex: 0 1 20%;
}

.grid-statistics .item-card-grid-first-row {
  flex: 1;
}
