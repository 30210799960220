@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-Heavy.eot);
  src: local('Avenir'), url(./Avenir-Heavy.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-Heavy.woff) format('woff'), local('Avenir'),
    url(./Avenir-Heavy.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-Heavy.svg#Avenir-Heavy) format('svg'), local('Avenir'),
    url(./Avenir-Heavy.eot?#iefix) format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-HeavyOblique.eot);
  src: local('Avenir'), url(./Avenir-HeavyOblique.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-HeavyOblique.woff) format('woff'), local('Avenir'),
    url(./Avenir-HeavyOblique.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-HeavyOblique.svg#Avenir-HeavyOblique) format('svg'), local('Avenir'),
    url(./Avenir-HeavyOblique.eot?#iefix) format('embedded-opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-Light.eot);
  src: local('Avenir'), url(./Avenir-Light.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-Light.woff) format('woff'), local('Avenir'),
    url(./Avenir-Light.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-Light.svg#Avenir-Light) format('svg'), local('Avenir'),
    url(./Avenir-Light.eot?#iefix) format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-LightOblique.eot);
  src: local('Avenir'), url(./Avenir-LightOblique.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-LightOblique.woff) format('woff'), local('Avenir'),
    url(./Avenir-LightOblique.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-LightOblique.svg#Avenir-LightOblique) format('svg'), local('Avenir'),
    url(./Avenir-LightOblique.eot?#iefix) format('embedded-opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-Medium.eot);
  src: local('Avenir'), url(./Avenir-Medium.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-Medium.woff) format('woff'), local('Avenir'),
    url(./Avenir-Medium.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-Medium.svg#Avenir-Medium) format('svg'), local('Avenir'),
    url(./Avenir-Medium.eot?#iefix) format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-MediumOblique.eot);
  src: local('Avenir'), url(./Avenir-MediumOblique.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-MediumOblique.woff) format('woff'), local('Avenir'),
    url(./Avenir-MediumOblique.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-MediumOblique.svg#Avenir-MediumOblique) format('svg'), local('Avenir'),
    url(./Avenir-MediumOblique.eot?#iefix) format('embedded-opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-Oblique.eot);
  src: local('Avenir'), url(./Avenir-Oblique.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-Oblique.woff) format('woff'), local('Avenir'),
    url(./Avenir-Oblique.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-Oblique.svg#Avenir-Oblique) format('svg'), local('Avenir'),
    url(./Avenir-Oblique.eot?#iefix) format('embedded-opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir-Roman.eot);
  src: local('Avenir'), url(./Avenir-Roman.woff2) format('woff2'), local('Avenir'),
    url(./Avenir-Roman.woff) format('woff'), local('Avenir'),
    url(./Avenir-Roman.ttf) format('truetype'), local('Avenir'),
    url(./Avenir-Roman.svg#Avenir-Roman) format('svg'), local('Avenir'),
    url(./Avenir-Roman.eot?#iefix) format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
