.metadata {
  display: inline-block;
  margin-right: 1em !important;
}

.id-card-popup,
.eligible-for-audition-track-popup {
  font-size: 100% !important;
}

.id-card-icon,
.eligible-for-audition-track-icon {
  display: inline-block;
  vertical-align: sub !important;
}

.syringe-icon.grey {
  filter: sepia(75%) brightness(1.25);
}
